const getAssetBlobUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'https://assets.dev.skycell.ch/'
    }
    const url = window.location

    if (url.host.includes('test')) {
        return 'https://assets.test.skycell.ch/'
    }
    if (url.host.includes('dev')) {
        return 'https://assets.dev.skycell.ch/'
    }
    return 'https://assets.skycell.ch/'
}

const getGlobalAssetUrl = (assetUrl) => {
    return `${getAssetBlobUrl()}${assetUrl}`
}

export default getGlobalAssetUrl
